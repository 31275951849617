import React, {createContext, useContext, useEffect, useState} from "react";
import {useTelegram} from "../hooks/useTelegram";
import * as client from "../utils/client";
import axios from "axios";
import * as constants from "../constants/constants";
import {isPrivilegeUser} from "../utils/userUtil";

const JwtContext = createContext();

export const useJwt = () => {
    return useContext(JwtContext);
};

export const axiosInstance = axios.create({
    baseURL: constants.BACKEND_URL + "/api",
    headers: {
        'Content-Type': 'application/json',
    }
});

export const JwtProvider = ({children}) => {
    const {cloudStorage, telegramUser} = useTelegram();
    const [jwtToken, setJwtToken] = useState(null);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [authError, setAuthError] = useState(null);
    const [userProblemsData, setUserProblemsData] = useState([]);
    const [problems, setProblems] = useState([]);
    const [users, setUsers] = useState([]);
    const [userReviewList, setUserReviewList] = useState([]);
    const [deferredProblems, setDeferredProblems] = useState([]);

    const loadData = async () => {
        if (!cloudStorage) {
            console.log("cloudStorage is not available.");
            setLoading(false);
            return;
        }

        const token = await new Promise((resolve, reject) => {
            cloudStorage.getItem("jwt", (err, token) => {
                if (err || !token) {
                    reject("Error getting JWT token");
                } else {
                    resolve(token);
                }
            });
        });

        setJwtToken(token);

        if (token && telegramUser) {
            try {
                const userJson = await client.login(token);
                setUser(userJson);

                const userProblems = await client.getUserProblems();
                setUserProblemsData(userProblems);

                if (userJson.role === 'ADMIN') {
                    const problemsJson = await client.getProblems();
                    setProblems(problemsJson);

                    const usersMap = problemsJson.reduce((acc, problem) => {
                        const author = problem.author;
                        if (!acc[author.tgId]) {
                            acc[author.tgId] = {
                                tgId: author.tgId,
                                username: author.username,
                                firstName: author.firstName,
                                lastName: author.lastName,
                                rating: author.rating,
                                problems: []
                            };
                        }
                        acc[author.tgId].problems.push(problem);
                        return acc;
                    }, {});

                    const usersArray = Object.values(usersMap);
                    setUsers(usersArray);
                }

                if (isPrivilegeUser(userJson)) {
                    const userReviewJson = await client.getReviewByUserId();
                    setUserReviewList(userReviewJson);

                    const deferredProblemJson = await client.getDeferredProblems();
                    setDeferredProblems(deferredProblemJson);
                }
            } catch (error) {
                console.error("Can't authorize user:", error.message);
                setAuthError("Не удалось авторизовать пользователя. Пожалуйста, попробуйте снова.");
            }
        }
    };

    useEffect(() => {
        loadData().finally(() => setLoading(false));
    }, [cloudStorage, telegramUser]);

    useEffect(() => {
        const requestInterceptor = axiosInstance.interceptors.request.use(
            (config) => {
                if (jwtToken) {
                    config.headers.Authorization = `Bearer ${jwtToken}`;
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        const responseInterceptor = axiosInstance.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response && error.response.status === 401) {
                    console.warn("Unauthorized access - возможно, нужно выйти из системы.");
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosInstance.interceptors.request.eject(requestInterceptor);
            axiosInstance.interceptors.response.eject(responseInterceptor);
        };
    }, [jwtToken]);

    return (
        <JwtContext.Provider
            value={{
                jwtToken, setJwtToken,
                user, setUser,
                loading,
                authError,
                userProblemsData, setUserProblemsData,
                problems,
                users, setUsers,
                userReviewList, setUserReviewList,
                deferredProblems, setDeferredProblems
            }}>
            {children}
        </JwtContext.Provider>
    );
};
