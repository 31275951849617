import {axiosInstance} from "../context/JwtContext";

export const registerUser = (userCredentials) => {
    return axiosInstance.post('/user/register', userCredentials)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
};

export const login = (jwt) => {
    return axiosInstance.post('/user/login',
        {},
        {headers: {Authorization: `Bearer ${jwt}`}})
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
};

export const getUsers = () => {
    return axiosInstance.post('/user/all')
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}

export const updateUserRole = (userId, role) => {
    return axiosInstance.post(`/user/updateRole?userId=${userId}&role=${role}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}

export const getUserProblems = () => {
    return axiosInstance.post('/problem/my')
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}

export const addProblem = (problemJson) => {
    return axiosInstance.post('/problem/add', problemJson, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
};

export const getRandomProblem = (theme, type) => {
    const params = new URLSearchParams();

    if (theme) {
        params.append('theme', theme);
    }

    if (type) {
        params.append('type', type);
    }

    return axiosInstance.post(`/problem/random?${params.toString()}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
};

export const getProblems = () => {
    return axiosInstance.post(`/problem/all`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}

export const getDeferredProblems = () => {
    return axiosInstance.post('/problem/deferred')
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}

export const deferProblem = (problemId) => {
    return axiosInstance.post(`/problem/defer?problemId=${problemId}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
};

// export const deleteProblem = (problemId) => {
//     return axiosInstance.post(`/problem/delete?problemId=${problemId}`)
//         .then(response => response.data)
//         .catch(error => {
//             throw error;
//         });
// }

export const getProblemById = (problemId) => {
    return axiosInstance.post(`/problem/${problemId}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}

export const addReview = (reviewCredentials) => {
    return axiosInstance.post('/review/add', reviewCredentials)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}

export const getReviewByUserId = () => {
    return axiosInstance.post('/review/my')
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}

export const getReviewById = (reviewId) => {
    return axiosInstance.post(`/review/${reviewId}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}

export const editReview = (reviewId, review) => {
    return axiosInstance.post(`/review/edit/${reviewId}`, review)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}