import React, {useEffect, useState} from 'react';
import './HomePage.css';
import {Link} from 'react-router-dom';
import AddProblemForm from '../../form/AddProblemForm/AddProblemForm.js';
import {useTelegram} from "../../../hooks/useTelegram";

const HomePage = ({user, userProblems, setUserProblems}) => {
    const {telegram} = useTelegram();
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        telegram.BackButton.hide();
    }, []);

    return (<div>
        <div className='problem-page-header'>
            <h2>Задачи ({userProblems.length})</h2>
            <button className='add-problem-button' onClick={() => setShowForm(!showForm)}>
                Добавить задачу
            </button>
        </div>
        <div className='problems-list'>
            {userProblems && userProblems.length > 0
                ? (userProblems.map((problem) =>
                    (<Link to={`/problem/${problem.id}`} key={problem.id} className='problem-card'>
                        <p className='problem-theme'>{problem.name} </p>
                        {problem.coAuthors && problem.coAuthors.length > 0 && (
                            <div className='problem-coauthors'>
                                <p>Co-Authors:</p>
                                {problem.coAuthors.map((coAuthor, index) => (
                                    <p key={index} className='coauthor-name'>{coAuthor}</p>
                                ))}
                            </div>
                        )}
                    </Link>)))
                : (<p>У вас еще нет задач.</p>)}
        </div>

        {showForm
            && <AddProblemForm
                user={user}
                onClose={() => setShowForm(false)}
                onProblemAdded={(newProblem) => setUserProblems(previousProblems => [...previousProblems, newProblem])}/>
        }
    </div>);
};

export default HomePage;
