import React, {useEffect, useRef, useState} from 'react';
import './ReviewsPage.css';
import * as client from "../../../utils/client";
import {Link} from "react-router-dom";
import AddReviewForm from '../../form/AddReviewForm/AddReviewForm';
import {useTelegram} from "../../../hooks/useTelegram";
import {ProblemTheme, ProblemType} from "../../../utils/problemUtil";
import {useJwt} from "../../../context/JwtContext";
import LatexRenderer from "../../LatexRenderer/LatexRenderer";
import * as constants from "../../../constants/constants";

const ReviewsPage = ({userReviewList, setUserReviewList, deferredProblems, setDeferredProblems}) => {
    const {jwtToken, user} = useJwt();
    const [showDeferredPool, setShowDeferredPool] = useState(false);
    const [showProblemModal, setShowProblemModal] = useState(false);
    const [showReviewHistory, setShowReviewHistory] = useState(false);
    const [selectedReview, setSelectedReview] = useState(null);
    const [problem, setProblem] = useState(null);
    const [selectedTheme, setSelectedTheme] = useState('ANY');
    const [selectedType, setSelectedType] = useState('ANY');

    const {telegram} = useTelegram();

    const modalContentRef = useRef(null);

    useEffect(() => {
        telegram.BackButton.hide();
    }, [telegram.BackButton]);

    useEffect(() => {
        if (showProblemModal && modalContentRef.current) {
            modalContentRef.current.scrollTo({top: 0, behavior: 'smooth'});
        }
    }, [problem, showProblemModal]);

    const getRandomProblem = () => {
        const theme = selectedTheme !== 'ANY' ? selectedTheme : null;
        const type = selectedType !== 'ANY' ? selectedType : null;

        client.getRandomProblem(theme, type)
            .then(randomProblemJson => {
                if (!randomProblemJson) {
                    alert('Задачи кончились!');
                } else {
                    setProblem(randomProblemJson);
                    setShowProblemModal(true);
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleDeferredProblemSelect = (selectedProblem) => {
        setProblem(selectedProblem);
        setShowDeferredPool(false);
        setShowProblemModal(true);
    };

    const handleAddReview = (reviewData, setGeneralError) => {
        const reviewCredentials = {
            tgId: user.tgId,
            problemId: problem.id,
            text: reviewData.text,
            beauty: reviewData.beauty,
            complexity: reviewData.complexity,
            wellKnown: reviewData.wellKnown,
            sourceUrl: reviewData.sourceUrl,
            tag: reviewData.tag
        };

        client.addReview(reviewCredentials)
            .then((review) => {
                setDeferredProblems(prevDeferredProblems =>
                    prevDeferredProblems.filter(p => p.id !== problem.id)
                );
                setUserReviewList([...userReviewList, review]);

                if (deferredProblems.length > 1) {
                    const nextProblem = deferredProblems.find(p => p.id !== problem.id);
                    if (nextProblem) {
                        setProblem(nextProblem);
                    } else {
                        setShowProblemModal(false);
                    }
                } else {
                    setShowProblemModal(false);
                }
            })
            .catch(error => {
                setGeneralError(error.response?.data || "An unexpected error occurred.");
            });
    };

    const deferProblem = () => {
        client.deferProblem(problem.id)
            .then(() => {
                setDeferredProblems(prevDeferredProblems => {
                    const existingIndex = prevDeferredProblems.findIndex(p => p.id === problem.id);

                    if (existingIndex >= 0) {
                        const updatedProblems = [...prevDeferredProblems];
                        updatedProblems[existingIndex] = problem;
                        return updatedProblems;
                    } else {
                        return [...prevDeferredProblems, problem];
                    }
                });

                setShowProblemModal(false);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleReviewClick = (review) => {
        setSelectedReview(review);
        setShowProblemModal(true);
    };

    const handleCloseModal = () => {
        setShowProblemModal(false);
        setSelectedReview(null);
    };

    return (
        <div className='overflow-hidden'>
            <div className='filters-container'>
                <div className='filter-group'>
                    <label htmlFor='theme-filter'>Тема:</label>
                    <select
                        id='theme-filter'
                        value={selectedTheme}
                        onChange={(e) => setSelectedTheme(e.target.value)}
                    >
                        <option value='ANY'>Любой</option>
                        {Object.entries(ProblemTheme).map(([key, value]) => (
                            <option key={key} value={key}>
                                {value}
                            </option>
                        ))}
                    </select>
                </div>

                <div className='filter-group'>
                    <label htmlFor='type-filter'>Тип:</label>
                    <select
                        id='type-filter'
                        value={selectedType}
                        onChange={(e) => setSelectedType(e.target.value)}
                    >
                        <option value='ANY'>Любой</option>
                        {Object.entries(ProblemType).map(([key, value]) => (
                            <option key={key} value={key}>
                                {value}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className='review-page-header-buttons'>
                <button className='add-problem-button' onClick={getRandomProblem}>
                    Получить случайную задачу
                </button>
                <button className='add-problem-button' onClick={() => setShowDeferredPool(true)}>
                    Отложенные задачи
                </button>
                <button className='add-problem-button' onClick={() => setShowReviewHistory(!showReviewHistory)}>
                    История ревью
                </button>
            </div>

            {showDeferredPool && (
                <div className='modal'>
                    <div className='modal-content'>
                        <h2>Отложенные задачи</h2>
                        <ul className='problems-list'>
                            {deferredProblems && deferredProblems.length > 0
                                ? deferredProblems.map(problem => (
                                    <li
                                        key={problem.id}
                                        className='problem-card'
                                        onClick={() => handleDeferredProblemSelect(problem)}
                                    >
                                        <div className='problem-theme'>{problem.name}</div>
                                    </li>
                                ))
                                : <p>У вас еще нет отложенных задач.</p>
                            }
                        </ul>
                        <button className='close-button' onClick={() => setShowDeferredPool(false)}>Закрыть</button>
                    </div>
                </div>
            )}

            {showReviewHistory && (
                <div className='modal'>
                    <div className='modal-content'>
                        <h2 className='review-history-header'>История ревью</h2>
                        {userReviewList && userReviewList.filter(review => !review.deferred).length > 0 ? (
                            <ul className='review-history-list'>
                                {userReviewList
                                    .filter(review => !review.deferred)
                                    .map((review, idx) => (
                                        <Link to={`/review/${review.id}`} key={review.id}
                                              onClick={() => handleReviewClick(review)}>
                                            <li className='review-history-item'>
                                                <span>#{idx + 1}: {review.tag}</span>
                                            </li>
                                        </Link>
                                    ))}
                            </ul>
                        ) : (
                            <p>Ревью еще нет.</p>
                        )}
                        <button className='close-button' onClick={() => setShowReviewHistory(false)}>Закрыть</button>
                    </div>
                </div>
            )}

            {showProblemModal && problem && (
                <div className='modal'>
                    <div className='modal-content' ref={modalContentRef}>
                        <p><strong>Название:</strong> {problem.name}</p>
                        <p><strong>Тема:</strong> {ProblemTheme[problem.theme] || problem.theme}</p>
                        <p><strong>Тип:</strong> {ProblemType[problem.type] || problem.type}</p>
                        <p><strong>Условие:</strong> <LatexRenderer latexCode={problem.statement}/></p>
                        <p>Условие (картинка):</p>
                        {problem.illustrationImageUrl &&
                            <div className='problem-media'>
                                <img
                                    src={`${constants.BACKEND_URL}/api/problemFiles/${problem.id}?jwt=${jwtToken}`}
                                    alt="Problem Illustration"
                                    className='problem-image'
                                />
                            </div>
                        }
                        <AddReviewForm key={problem.id} onSubmit={handleAddReview} onClose={handleCloseModal}/>
                        {!selectedReview && (
                            <div className='review-buttons fullwidth'>
                                <button onClick={deferProblem}>Отложить</button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ReviewsPage;
